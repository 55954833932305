import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/utbot-site-staging/utbot-site-staging/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`UnitTestBot works with Windows, Linux, and macOS. In most cases you should not worry about the compatibility with your operational system.`}</p>
    <h3 {...{
      "id": "to-generate-tests-with-unittestbot"
    }}>{`To generate tests with UnitTestBot:`}</h3>
    <p>{`You have to install IntelliJ IDEA (versions from 2022.1 to 2022.1.4 are supported).`}</p>
    <h3 {...{
      "id": "to-contribute-to-unittestbot-project"
    }}>{`To contribute to UnitTestBot project:`}</h3>
    <p>{`You have to `}<strong parentName="p">{`install`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`IntelliJ IDEA (versions from 2022.1 to 2022.1.4 are supported);`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`JDK 11;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Kotlin 1.7.0 or later.`}</p>
      </li>
    </ul>
    <p>{`You also need to `}<strong parentName="p">{`configure development environment`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`JAVA_HOME`}</inlineCode>{` environment variable should contain the path to JDK 11 installation directory.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`PATH`}</inlineCode>{` environment variable should contain the path to the `}<inlineCode parentName="p">{`bin`}</inlineCode>{` folder of JDK 11 installation directory.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`KOTLIN_HOME`}</inlineCode>{` environment variable should contain the path to the `}<inlineCode parentName="p">{`kotlinc`}</inlineCode>{` folder of Kotlin (1.7.0 or later) installation directory.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Project SDK  (1) and Gradle SDK (2) should be set to JDK 11:`}</p>
      </li>
    </ul>
    <p>{`(1) `}<strong parentName="p">{`IntelliJ IDEA`}</strong>{` > `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Project Structure`}</strong>{` > `}<strong parentName="p">{`Project Settings`}</strong>{` > `}<strong parentName="p">{`Project`}</strong>{` > `}<strong parentName="p">{`SDK`}</strong>{`,`}</p>
    <p>{`(2) `}<strong parentName="p">{`IntelliJ IDEA`}</strong>{` > `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Build, Execution, Deployment`}</strong>{` > `}<strong parentName="p">{`Build Tools`}</strong>{` > `}<strong parentName="p">{`Gradle`}</strong>{`.`}</p>
    <p><em parentName="p">{`Note:`}</em>{` if the environment variables lead to unsupported JDK or Kotlin versions, you won't be able to build the UnitTestBot project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      